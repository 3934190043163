<template>
  <div class="slow-log">
    <h1 class="title">{{ project }} 于 {{ date }} 的慢查日志</h1>
    <el-table class="slow-table" size="mini"
              :row-class-name="rowClass"
              :data="tableData"
              :default-sort="defaultSort"
    >
      <el-table-column prop="ReportDate" label="日期" width="100"></el-table-column>
      <el-table-column prop="DbName" label="DBName" width="150"></el-table-column>
      <el-table-column prop="HotLevel" sortable label="严重等级" width="50"></el-table-column>
      <el-table-column prop="TotalExecutionCounts" sortable label="执行次数" width="50"></el-table-column>
      <el-table-column label="执行时间(毫秒)">
        <el-table-column prop="AvgExecutionTime" sortable label="平均" width="70"></el-table-column>
        <el-table-column prop="MaxExecutionTime" sortable label="最大" width="70"></el-table-column>
      </el-table-column>
      <el-table-column label="锁等待时间(毫秒)">
        <el-table-column prop="AvgLockTime" sortable label="平均" width="70"></el-table-column>
        <el-table-column prop="MaxLockTime" sortable label="最大" width="70"></el-table-column>
      </el-table-column>
      <el-table-column label="扫描行数">
        <el-table-column prop="AvgParseRowCounts" sortable label="平均" width="70"></el-table-column>
        <el-table-column prop="MaxParseRowCounts" sortable label="最大" width="70"></el-table-column>
      </el-table-column>
      <el-table-column label="返回行数">
        <el-table-column prop="AvgReturnRowCounts" sortable label="平均" width="70"></el-table-column>
        <el-table-column prop="MaxReturnRowCounts" sortable label="最大" width="70"></el-table-column>
      </el-table-column>
      <el-table-column prop="SqlTemplate" label="SQL模版">
        <template #default="scope">
          <p v-if="!scope.row.ShowSQL" class="sql-template" :class="{'all': scope.row.ShowALL}">
            <span class="btn" @click="scope.row.ShowSQL = !scope.row.ShowSQL">[展示样本]</span>
            <span class="btn" @click="copy(scope.row.SqlTemplate)"> [复制] </span>
            <span class="btn more" @click="scope.row.ShowALL = !scope.row.ShowALL"></span>
            <span class="allow-select">{{ scope.row.SqlTemplate }}</span>
          </p>
          <p v-else class="sql-template" :class="{'all': scope.row.ShowALL}">
            <span class="btn" @click="scope.row.ShowSQL = !scope.row.ShowSQL">[展示模板]</span>
            <span class="btn" @click="copy(scope.row.SqlText)"> [复制] </span>
            <span class="btn more" @click="scope.row.ShowALL = !scope.row.ShowALL"></span>
            <span class="allow-select">{{ scope.row.SqlText }}</span>
          </p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import axios from "axios";
import clipboardCopy from "clipboard-copy";
import {ElMessage} from "element-plus";

interface SlowLogEntry {
  AvgExecutionTime: number
  AvgLockTime: number
  AvgParseRowCounts: number
  AvgReturnRowCounts: number
  DbName: string
  HotLevel: number
  Id: number
  MaxExecutionTime: number
  MaxLockTime: number
  MaxParseRowCounts: number
  MaxReturnRowCounts: number
  ReportDate: string
  SqlHash: string
  SqlTemplate: string
  SqlText: string
  TotalExecutionCounts: number
}

@Options({})
export default class HelloWorld extends Vue {
  protected project = ""
  protected date = this.getYesterday()
  protected tableData: Array<SlowLogEntry> = []
  protected defaultSort = {prop: 'HotLevel', order: 'descending'}

  protected async getData(): Promise<Array<SlowLogEntry>> {
    const resp = await axios.get(`/api/get_slow_log_details?date=${this.date}&db_name=${this.project}`);
    return resp.data
  }

  async mounted() {
    let url = new URL(location.href);
    if (url.searchParams.has("db_name")){
      this.project = `${url.searchParams.get("db_name")}`
    }

    if (url.searchParams.has("date")){
      this.date = `${url.searchParams.get("date")}`
    }

    this.tableData = await this.getData()
  }

  protected rowClass({row}: { row: SlowLogEntry }) {
    if (row.HotLevel >= 3) {
      return "slow-sql-danger"
    } else if (row.HotLevel >= 1) {
      return "slow-sql-warning"
    } else {
      return "slow-sql-normal"
    }
  }

  protected copy(text: string) {
    clipboardCopy(text)
    ElMessage.success('复制成功')
  }

  protected getYesterday() {
    let yesterday = new Date(new Date().getTime() - (24 * 3600 * 1000))
    let year = yesterday.getFullYear()
    let month = yesterday.getMonth() + 1;
    let day = yesterday.getDate()
    let m = month.toString();
    let d = day.toString();
    if (month >= 1 && month <= 9) {
      m = "0" + month.toString();
    }
    if (day >= 1 && day <= 9) {
      d = "0" + day.toString();
    }
    return year + "-" + m + "-" + d
  }
}
</script>
<style lang="scss">
.el-table tr.slow-sql-danger {
  background: #f56c6c;
  color: white;

  &:hover > td.el-table__cell {
    background: #f78989 !important;
    color: white;
  }

  .sql-template .btn {
    color: #ebf3ff !important;
  }
}

.el-table tr.slow-sql-warning {
  background: #e6a23c;
  color: white;

  &:hover > td.el-table__cell {
    background: #ebb563 !important;
    color: white;
  }

  .sql-template .btn {
    color: #ebf3ff !important;
  }
}
</style>
<style lang="scss" scoped>
.slow-log {
  padding: 16px;

  .title {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 16px;
    color: #444;
  }

  .slow-table {
    width: 100%;
    color: black;

    .sql-template {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .more:after {
        content: "＞";
        display: inline-block;
        transition: transform 0.2s ease-in-out;
      }

      &.all {
        white-space: inherit;
        text-overflow: inherit;

        .more:after {
          transform: rotateZ(90deg);
        }
      }

      .btn {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
</style>
