
import {Options, Vue} from 'vue-class-component';
import SlowSQL from './components/SlowSQL.vue';

@Options({
  components: {
    SlowSQL,
  },
})
export default class App extends Vue {
}
