
import {Options, Vue} from 'vue-class-component';
import axios from "axios";
import clipboardCopy from "clipboard-copy";
import {ElMessage} from "element-plus";

interface SlowLogEntry {
  AvgExecutionTime: number
  AvgLockTime: number
  AvgParseRowCounts: number
  AvgReturnRowCounts: number
  DbName: string
  HotLevel: number
  Id: number
  MaxExecutionTime: number
  MaxLockTime: number
  MaxParseRowCounts: number
  MaxReturnRowCounts: number
  ReportDate: string
  SqlHash: string
  SqlTemplate: string
  SqlText: string
  TotalExecutionCounts: number
}

@Options({})
export default class HelloWorld extends Vue {
  protected project = ""
  protected date = this.getYesterday()
  protected tableData: Array<SlowLogEntry> = []
  protected defaultSort = {prop: 'HotLevel', order: 'descending'}

  protected async getData(): Promise<Array<SlowLogEntry>> {
    const resp = await axios.get(`/api/get_slow_log_details?date=${this.date}&db_name=${this.project}`);
    return resp.data
  }

  async mounted() {
    let url = new URL(location.href);
    if (url.searchParams.has("db_name")){
      this.project = `${url.searchParams.get("db_name")}`
    }

    if (url.searchParams.has("date")){
      this.date = `${url.searchParams.get("date")}`
    }

    this.tableData = await this.getData()
  }

  protected rowClass({row}: { row: SlowLogEntry }) {
    if (row.HotLevel >= 3) {
      return "slow-sql-danger"
    } else if (row.HotLevel >= 1) {
      return "slow-sql-warning"
    } else {
      return "slow-sql-normal"
    }
  }

  protected copy(text: string) {
    clipboardCopy(text)
    ElMessage.success('复制成功')
  }

  protected getYesterday() {
    let yesterday = new Date(new Date().getTime() - (24 * 3600 * 1000))
    let year = yesterday.getFullYear()
    let month = yesterday.getMonth() + 1;
    let day = yesterday.getDate()
    let m = month.toString();
    let d = day.toString();
    if (month >= 1 && month <= 9) {
      m = "0" + month.toString();
    }
    if (day >= 1 && day <= 9) {
      d = "0" + day.toString();
    }
    return year + "-" + m + "-" + d
  }
}
