<template>
  <SlowSQL class="cannot-select"/>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import SlowSQL from './components/SlowSQL.vue';

@Options({
  components: {
    SlowSQL,
  },
})
export default class App extends Vue {
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.cannot-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.allow-select {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

</style>
